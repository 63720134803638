.inactive {
  color: #fb4e58 !important;
  background-color: #ffecee !important;
  border: 1px solid #ffcbcd;
  border-radius: 10px !important;
  font-size: 42px;
  padding: 7px;
}
.active {
  color: white !important;
  background-image: linear-gradient(to right, #ff9966, #ff5e62) !important;
}

.details .cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 12px; */
}

.details .img-div {
  height: 65vh;
  cursor: pointer;
}

.details .leftBorder {
  border: 1px solid gray !important;
}
@media only screen and (max-width: 425px) {
  .details .cover {
    height: auto !important;
  }
  .details .img-div {
    height: auto !important;
  }
}
@media only screen and (max-width: 375px) {
  .active,
  .inactive {
    font-size: 14px !important;
  }
  .details .inactive {
    font-size: 42px !important;
  }
}
@media only screen and (max-width: 320px) {
  .active,
  .inactive {
    font-size: 12px !important;
  }
}
