.cell-phone > .android {
  width: 280px;
}
/* .cell-phone > .iphone {
  width: 100%;
} */
.android-download {
  width: 180px;
}
.iphone-download {
  width: 180px;
  padding: 1.5%;
}
.filterBar .active {
  color: white !important;
  background-image: linear-gradient(to right, #ff9966, #ff5e62) !important;
  font-family: "gilroy-medium" !important;
  border-radius: 4px !important;
}
.filterBar .inactive {
  color: #fb4e58 !important;
  background-color: #ffecee !important;
  border: 1px solid #ffcbcd;
  font-family: "gilroy-regular" !important;
  border-radius: 4px !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b7c1cf;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #939ba7;
}
.scrollLeft:hover,
.scrollLeft:focus,
.scrollRight:hover,
.scrollRight:focus {
  background-color: #262c36 !important;
}
@media only screen and (max-width: 1230px) {
  .cell-phone > .android {
    width: 200px !important;
  }
  /* .cell-phone > .iphone {
    width: 130px !important;
  } */
}
@media only screen and (max-width: 1024px) {
  .iphone-download {
    padding: 3% !important;
  }
}
@media only screen and (max-width: 786px) {
  .iphone-download {
    padding: 2% !important;
  }
}
@media only screen and (max-width: 425px) {
  .android-download {
    width: 180px !important;
  }
  .iphone-download {
    width: 170px !important;
    height: 50px !important;
    padding: unset !important;
    padding-left: 3% !important;
  }
}
@media only screen and (max-width: 375px) {
  /* .android-download {
    width: 120px !important;
  } */
  .iphone-download {
    padding-left: 4% !important;
  }
}

@media only screen and (max-width: 320px) {
  /* .android-download {
    width: 100px !important;
  } */
  .iphone-download {
    width: 165px !important;

    padding-left: 4% !important;
  }
}
