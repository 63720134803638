.container-details {
  border: 1px solid red;
}

.App {
  display: flex;
  flex-direction: column;
  height: inherit;
}
.featured {
  color: rgb(255, 255, 255) !important;
  background-image: linear-gradient(to right, #ff9966, #ff5e62) !important;
  font-family: "gilroy-bold" !important;
  position: absolute;
  right: 3px;
  padding: 7px !important;
  bottom: 3px !important;
  border-radius: 4px;
  font-size: smaller !important;
  letter-spacing: 1px;
}
.css-yuzwfj:focus,
.css-yuzwfj[data-focus],
.css-taj3dd:focus,
.css-taj3dd[data-focus],
.css-1150rfa:focus,
.css-1150rfa[data-focus],
.css-cixnz0:focus,
.css-cixnz0[data-focus],
.css-dn5esd:focus,
.css-dn5esd[data-focus],
button {
  box-shadow: unset !important;
}
.css-1wx5wza:focus,
.css-1wx5wza[data-focus] {
  border-color: transparent !important;
}
.css-r6z5ec {
  z-index: 4 !important;
  width: 90% !important;
}

.ellipsis-anim span {
  opacity: 0;
  -webkit-animation: ellipsis-dot 2s infinite;
  animation: ellipsis-dot 2s infinite;
}

.ellipsis-anim span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.ellipsis-anim span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ellipsis-dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-enter .splashScreen {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .splashScreen {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .splashScreen {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .splashScreen {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .splashScreen,
.fade-exit-active .splashScreen {
  transition: opacity 500ms, transform 500ms;
}
@media only screen and (max-width: 1024px) {
  .SplashScreen img {
    width: 200px !important;
    height: 200px !important;
  }
}

@media only screen and (max-width: 786px) {
  .SplashScreen img {
    width: 170px !important;
    height: 170px !important;
  }
}

@media only screen and (max-width: 425px) {
  .App {
    height: auto !important;
  }
  .SplashScreen img {
    width: 140px !important;
    height: 140px !important;
  }
}

@media only screen and (max-width: 375px) {
  .SplashScreen img {
    width: 110px !important;
    height: 110px !important;
  }
}

@media only screen and (max-width: 320px) {
  .SplashScreen img {
    width: 80px !important;
    height: 80px !important;
  }
}
