/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg'
 */
/* Black */
@font-face {
  font-family: "gilroy-black";
  src: url("fonts/gilroy-black-webfont.woff2") format("woff2"),
    url("fonts/gilroy-black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Black Italic */
@font-face {
  font-family: "gilroy-black-italic";
  src: url("fonts/gilroy-blackitalic-webfont.woff2") format("woff2"),
    url("fonts/gilroy-blackitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Bold */
@font-face {
  font-family: "gilroy-bold";
  src: url("fonts/gilroy-bold-webfont.woff2") format("woff2"),
    url("fonts/gilroy-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Bold Italic */
@font-face {
  font-family: "gilroy-bold-italic";
  src: url("fonts/gilroy-bolditalic-webfont.woff2") format("woff2"),
    url("fonts/gilroy-bolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Extra Bold */
@font-face {
  font-family: "gilroy-extra-bold";
  src: url("fonts/gilroy-extrabold-webfont.woff2") format("woff2"),
    url("fonts/gilroy-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Extra Bold Italic */
@font-face {
  font-family: "gilroy-extra-bold-italic";
  src: url("fonts/gilroy-extrabolditalic-webfont.woff2") format("woff2"),
    url("fonts/gilroy-extrabolditalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Heavy */
@font-face {
  font-family: "gilroy-heavy";
  src: url("fonts/gilroy-heavy-webfont.woff2") format("woff2"),
    url("fonts/gilroy-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Heavy Italic */
@font-face {
  font-family: "gilroy-heavy-italic";
  src: url("fonts/gilroy-heavyitalic-webfont.woff2") format("woff2"),
    url("fonts/gilroy-heavyitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Light */
@font-face {
  font-family: "gilroy-light";
  src: url("fonts/gilroy-light-webfont.woff2") format("woff2"),
    url("fonts/gilroy-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Light Italic */
@font-face {
  font-family: "gilroy-light-italic";
  src: url("fonts/gilroy-lightitalic-webfont.woff2") format("woff2"),
    url("fonts/gilroy-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Medium */
@font-face {
  font-family: "gilroy-medium";
  src: url("fonts/gilroy-medium-webfont.woff2") format("woff2"),
    url("fonts/gilroy-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Medium Italic */
@font-face {
  font-family: "gilroy-medium-italic";
  src: url("fonts/gilroy-mediumitalic-webfont.woff2") format("woff2"),
    url("fonts/gilroy-mediumitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Regular */
@font-face {
  font-family: "gilroy-regular";
  src: url("fonts/Gilroy-Regular.woff2") format("woff2"),
    url("fonts/Gilroy-Regular.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Regular Italic */
@font-face {
  font-family: "gilroy-regular-italic";
  src: url("fonts/Gilroy-RegularItalic.woff2") format("woff2"),
    url("fonts/Gilroy-RegularItalic.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Semi Bold */
@font-face {
  font-family: "gilroy-semi-bold";
  src: url("fonts/Gilroy-Semibold.woff2") format("woff2"),
    url("fonts/Gilroy-Semibold.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Semi Bold Italic */
@font-face {
  font-family: "gilroy-semi-bold-italic";
  src: url("fonts/Gilroy-SemiboldItalic.woff2") format("woff2"),
    url("fonts/Gilroy-SemiboldItalic.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Thin */
@font-face {
  font-family: "gilroy-thin";
  src: url("fonts/Gilroy-Thin.woff2") format("woff2"),
    url("fonts/Gilroy-Thin.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Thin Italic */
@font-face {
  font-family: "gilroy-thin-italic";
  src: url("fonts/Gilroy-ThinItalic.woff2") format("woff2"),
    url("fonts/Gilroy-ThinItalic.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* UltraLight */
@font-face {
  font-family: "gilroy-ultra-light";
  src: url("fonts/Gilroy-UltraLight.woff2") format("woff2"),
    url("fonts/Gilroy-UltraLight.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* UltraLight Italic */
@font-face {
  font-family: "gilroy-ultralight-italic";
  src: url("fonts/Gilroy-UltraLightItalic.woff2") format("woff2"),
    url("fonts/Gilroy-UltraLightItalic.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
#root {
  height: 100vh;
}
body {
  margin: 0;
  font-family: gilroy-regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.behavior {
  scroll-behavior: smooth !important;
}
.pac-container,
.MuiPickersPopper-root {
  z-index: 10000 !important;
}
