.poweredBy > img {
  width: 200px;
}

.poweredBy > .tempo {
  height: 70px;
}

.checkout .cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  /* border-radius: 12px; */
}

@media only screen and (max-width: 425px) {
  .poweredBy > .tempo {
    height: 80px !important;
  }
  .poweredBy > .stripe {
    height: 80px !important;
    object-fit: contain !important;
  }
}
