.nav-bar {
  margin-inline: 7%;
  margin-top: 14px;
}
.social-btn {
  color: #ff5e62 !important;
  font-size: 32 !important;
}

.social-btn:hover {
  background-color: #ff5e62 !important;
  color: #fff !important;
}

.btn-pressed {
  border-bottom: 2px solid #ff5e62;
  color: #000 !important;
  border-radius: unset !important;
}
.nav-bar .chakra-modal__content-container section {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.MuiFormHelperText-root,
.MuiInputBase-root {
  color: unset !important;
}
.Field {
  color: #fff !important;
}
.ais-SearchBox-input,
.autofill {
  padding: 7px;
  background-color: transparent;
  color: white;
  font-size: 19px;
  border-bottom: 1px solid #ff9966;
  width: 100% !important;
}

.ais-SearchBox-input:focus,
.autofill:focus {
  border-bottom: 2px solid #dd6b20;
}
option {
  background-color: rgba(26, 25, 25, 0.1) !important;
  opacity: 0.5 !important;
}
.css-bmqd2f-control {
  box-shadow: none !important;
}
.css-b62m3t-container,
.MuiPickersDateRangePickerInput-root,
.css-uc6l60 {
  width: 100% !important;
}

.ais-RefinementList {
  color: white !important;
}
.left-panel {
  flex-grow: 0.1 !important;
}
.ais-RefinementList-item {
  margin-block: 7%;
}
.ais-RefinementList-labelText {
  margin-left: 3%;
}

.ais-RefinementList-list {
  list-style: none !important;
}
